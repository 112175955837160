<template>
  <v-container fluid>
    <v-flex class="mx-4">
      <v-card class="rounded secondarygrad mb-4">
        <v-row style="margin: 0px">
          <v-col>
            <v-card color="transparent" elevation="0" style="padding: 10px; text-align: center !important">
              <v-card-text style="margin-bottom: 25px">
                <div align="center" style="margin-bottom: 10px; margin-top: 20%">
                  <h1 style="margin-bottom: 40px">Godial + Telecmi</h1>
                </div>
                <div style="padding: 5px 15px; text-align: center">
                  <div style="text-align: left">
                    <p style="
                            margin-top: 20px;
                            font-size: 18px;
                            font-weight: 600;
                            margin-bottom: 10px;
                          ">
                      How it works?
                    </p>
                    <p>
                      Dial out using your desktop or mobile app using TeleCMI Cloud Telephony.
                      <br />
                    <ul>
                      <li>Number Masking ( Unified Virtual Number for your team )</li>
                      <li>Incoming and Outgoing Tracking</li>
                      <li>Call Recording on All Calls</li>
                    </ul>

                    <br />
                    <!-- Alert and disclaimer-->


                    </p>
                  </div>

                </div>
              </v-card-text>
              <v-footer color="transparent" absolute>
                <v-flex style="text-align: center !important">
                  <p style="
                          font-size: 12px;
                          opacity: 0.9;
                          margin-bottom: 0px;
                        ">
                    Feel free to reach out to us at
                    <a href="mailto:support@godial.cc">support@godial.cc</a>
                    if you are interested in these features
                  </p>
                </v-flex>
              </v-footer>
            </v-card>
          </v-col>
          <v-col>
            <v-card color="transparent" elevation="0" class="px-md-15 py-md-8"
              style="margin-top:25px; text-align: center">
              <v-card-title primary-title class="justify-center">Connect Your Telecmi CRM</v-card-title>

              <v-card-text>
                <v-text-field v-model="appid" label="Your app ID" solo type="number" />
                <v-text-field v-model="secret" label="Your app secret" solo />

                <v-checkbox v-model="defaultDialerTelecmi" style="margin-top: -3px" color="primary"
                  label="Use Telecmi as default dialer"></v-checkbox>

                <v-btn color="primarygrad" block @click="connectToTelecmi">
                  Connect
                </v-btn>
              </v-card-text>
            </v-card>





          </v-col>
        </v-row>


        <v-card color="transparent" elevation="0" style="margin-bottom:10px">
          <v-layout row wrap px-5>
            <v-flex xs7 sm9 md10 style="text-align: center;
                      padding: 5px;">
              <v-text-field v-model="token" label="Telecmi Webhook URL" outlined readonly
                style="padding: 8px 0;"></v-text-field>
            </v-flex>

            <v-flex xs5 sm3 md2 style="text-align: center; padding: 5px; ">
              <v-btn style="margin-left:10px; margin-top:8px; color:primary; padding: 28px ;" mt-5
                @click="copy(token)">COPY URL</v-btn>
            </v-flex>
          </v-layout>


          <v-layout row wrap px-5>
            <v-flex xs7 sm9 md10 style="text-align: center;
                      padding: 5px;">
              <v-text-field v-model="callflow_token" label="Telecmi Call Flow URL" outlined readonly
                style="padding: 8px 0;"></v-text-field>
            </v-flex>

            <v-flex xs5 sm3 md2 style="text-align: center; padding: 5px; ">
              <v-btn style="margin-left:10px; margin-top:8px; color:primary; padding: 28px ;" mt-5
                @click="copy(callflow_token)">COPY URL</v-btn>
            </v-flex>
          </v-layout>

          <v-snackbar v-model="snackbar" :bottom="true" :right="true" timeout="2000">Copied!</v-snackbar>
        </v-card>
      </v-card>
      <v-row>

        <v-col>

          <v-alert type="warning" border="left" elevation="2" colored-border style="margin-top: 20px">GoDial
            is not a telecom, voip or communications provider. We can integrate with Telecmi and allow you to
            place calls from within GoDial using TeleCMI. Please ensure that you follow all local rules and
            guidelines in regards to Telecommunications. GoDial is in no manner involved, liable or affiliated with
            Governing bodies or Telecom Companies.</v-alert>

        </v-col>
      </v-row>

      <v-row>
        <v-col>
          <v-tabs background-color="primarygrad accent-4" center-active dark>
            <v-tab>Map Users</v-tab>
            <v-tab>Incoming Settings</v-tab>

            <v-tab-item>
              <v-row style="padding:20px">
                <v-col cols="6" sm="5">
                  <v-autocomplete v-model="godial.user" class="pt-1" :items="godialUsers" item-text="name"
                    label="Godial Users" prepend-inner-icon="person" autocomplete="false" outlined dense
                    return-object />
                </v-col>
                <v-col cols="6" sm="5">
                  <v-autocomplete v-model="telecmi.user" class="pt-1" :items="telecmiUsers" item-text="name"
                    label="Telecmi Users" prepend-inner-icon="people" autocomplete="false" outlined dense
                    return-object />
                </v-col>
                <v-col cols="12" sm="2">
                  <v-btn :disabled="!connected" class="mb-3 mb-sm-0 py-1 my-1" :class="connected ? 'primarygrad' : ''"
                    @click="addGodialTelecmiUsers">Map user
                  </v-btn>
                </v-col>
              </v-row>

              <v-data-table v-if="telecmiGodialUsers.length > 0" :headers="headers" :items="telecmiGodialUsers"
                style="min-width: 78vw;padding:20px">
                <template v-slot:item.action="{ item }">
                  <v-btn icon color="error" @click="removeGodialTelecmiUsers(item)">
                    <v-icon>mdi-trash-can-outline</v-icon>
                  </v-btn>
                </template>
              </v-data-table>
            </v-tab-item>
            <v-tab-item>
              <v-row>
                <v-col style="padding:20px">

                  <v-checkbox v-model="telecmiIntegration.config.incoming.enabled"
                    label="Handle Incoming Calls"></v-checkbox>

                  <v-text-field v-model="telecmiIntegration.config.incoming.welcome_music"
                    label="Greeting Music Url"></v-text-field>

                  <v-text-field v-model="telecmiIntegration.config.incoming.waiting_music"
                    label="Waiting Music Url"></v-text-field>

                  <v-autocomplete v-model="telecmiIntegration.config.incoming.result" :items="telecmiUsers"
                    return-object item-text="name" multiple label="Incoming Agents"></v-autocomplete>


                  <v-autocomplete v-model="telecmiIntegration.config.incoming.list" :items="lists" item-value="id"
                    item-text="name" label="Incoming List"></v-autocomplete>

                  <v-btn @click="connectToTelecmi">Save</v-btn>
                </v-col>
              </v-row>
            </v-tab-item>
          </v-tabs>
        </v-col>
      </v-row>
    </v-flex>

    <!-- Loader -->
    <v-dialog v-model="loader" persistent width="300">
      <v-card color="primary white--text" dark>
        <v-card-text style="padding: 18px 25px">
          <h3 style="margin-bottom: 5px; font-weight: 400; font-size: 16px">
            Please Wait...
          </h3>
          <v-progress-linear indeterminate color="white" class="mb-0"></v-progress-linear>
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  data() {
    return {
      // appid: 2224475,
      // secret: "37d75f9c-918a-4b5f-983a-0325eff71221",
      token: "",
      callflow_token: "",
      snackbar: false,
      defaultDialerTelecmi: false,
      appid: "",
      secret: "",
      connected: true,
      tabs: 1,
      godial: {
        user: null,
      },
      telecmi: {
        user: null,
      },
      telecmiIntegration: {
        type: "telecmi",
        config: {
          appid: "",
          secret: "",
          defaultDialerTelecmi: false,
          incoming: {
            code: 200,
            loop: 2,
            welcome_music: "",
            waiting_music: "",
            enabled: true,
            result: [],
            list: "",
          },
        },
        companyId: "",
        enabled: false,
      },
      loader: false,
      godialUsers: [],
      telecmiUsers: [],
      lists: [],
      telecmiGodialUsers: [],
      headers: [
        { text: "Godial User", value: "name" },
        { text: "Telecmi user", value: "telecmi.name" },
        { text: "Action", value: "action" },
      ],
    };
  },
  computed: {
    ...mapGetters(["ENDPOINT", "companyDetails", "selectedTeam"]),
  },
  created() {
    this.refresh();
    this.telecmiIntegration.companyId = this.companyDetails.id;
  },
  methods: {
    async refresh() {
      await this.fetchAccounts();
      await this.fetchLists();
      await this.getTelecmiDetails();
      await this.generateToken();

      if (this.appid != "") {
        this.telecmiList();
      }
    },
    generateToken() {
      const self = this;
      var aToken = self.$store.getters.user.token;

      self.$http
        .get(`${this.ENDPOINT}/externals/token?access_token=${aToken}`)
        .then((response) => {
          // This part is firing for outgoing call
          this.token = this.ENDPOINT + "/telecmis/cdr?access_token=" + response.body.id;
          // This part is firing for incoming call
          this.callflow_token = this.ENDPOINT + "/telecmis/callflow?access_token=" + response.body.id;
        })
        .catch((err) => {
          self.$swal({ type: "error", text: err.body.error.message });
        });
    },

    copy(link) {
      const self = this;
      this.$copyText(link).then(
        function (e) {
          self.snackbar = true;
          console.log(e);
        },
        function (e) {
          console.log(e);
        }
      );
    },
    getTeamIds() {
      var teams = [];
      if (this.selectedTeam == "allTeams") {
        teams = _.map(this.teams, "id");
      } else {
        teams.push(this.selectedTeam);
      }

      teams = _.without(teams, "allTeams");
      return teams;
    },

    async fetchLists() {
      try {
        this.lists = [];
        var response = [];

        var teamIds = this.getTeamIds();

        // Teams sections
        response = await this.$http.post(`${this.ENDPOINT}/lists/fetch`, {
          teams: teamIds,
        });
        this.lists = response.body;

        if (this.lists.length < 1) {
          this.loader = false;
          return;
        }
      } catch (e) {
        this.loader = false;
        console.log("error in list fetch", e);
      }
    },

    // Fetch Accounts
    async fetchAccounts() {
      this.godialUsers = [];

      try {
        var teams = [];
        teams = await this.getTeamIds();

        var response = await this.$http.post(
          `${this.$store.state.ENDPOINT}/accounts/fetch`,
          {
            teams: teams,
          }
        );

        this.godialUsers = response.body;
        this.filterGodialTelecmiUser();

        if (this.godialUsers.length < 1) {
          this.godial.user.push(this.godialUsers[0].id);
        }
      } catch (e) {
        if (e.body.error && e.body.error.message)
          return this.$swal({ type: "error", text: e.body.error.message });
        else
          return this.$swal({
            type: "error",
            text: "Error while fetching accounts",
          });
      }
    },

    async getTelecmiDetails() {
      var resp = await this.$http.get(
        `${this.ENDPOINT}/integrations?filter[where][companyId]=${this.companyDetails.id}&filter[where][type]=telecmi`
      );

      if (resp.body.length > 0) this.telecmiIntegration = resp.body[0];
      this.appid = this.telecmiIntegration.config.appid;
      this.secret = this.telecmiIntegration.config.secret;
      this.defaultDialerTelecmi = this.telecmiIntegration.config.defaultDialerTelecmi;

      console.log(this.secret);

      console.log("this.defaultDialerTelecmi", this.defaultDialerTelecmi);
    },
    async filterGodialTelecmiUser() {
      this.telecmiGodialUsers = this.godialUsers.filter((user) => user.telecmi);
    },
    // Fetch Tele CMI Agent list
    async connectToTelecmi() {
      var self = this;
      if (this.appid === "" || this.secret === "") {
        return this.$swal({
          title: "Warning",
          text: "Please fill the App Id and App Secret",
          type: "warning",
          confirmButtonText: "OK",
        });
      }

      try {
        var response = await this.$http.post(
          "https://rest.telecmi.com/v2/user/list",
          {
            appid: parseInt(this.appid),
            secret: this.secret,
            page: 1,
            limit: 10,
          }
        );

        console.log("response", response);
      } catch (e) {
        console.log(e);
      }

      if (response.body.code == 200) {
        this.telecmiIntegration.companyId = this.companyDetails.id;
        this.telecmiIntegration.type = "telecmi";
        this.telecmiIntegration.config.appid = this.appid;
        this.telecmiIntegration.config.secret = this.secret;
        this.telecmiIntegration.config.defaultDialerTelecmi = this.defaultDialerTelecmi;
        this.telecmiIntegration.enabled = true;

        var res = {};

        console.log("telecmiIntegration", this.telecmiIntegration);

        if (typeof this.telecmiIntegration.id == "undefined") {
          // Add Indiamart Details In Company Data
          res = await this.$http.post(
            `${this.ENDPOINT}/integrations`,
            this.telecmiIntegration
          );
        } else {
          var data = JSON.parse(JSON.stringify(this.telecmiIntegration));
          delete data.id;
          res = await this.$http.put(
            `${this.ENDPOINT}/integrations/${this.telecmiIntegration.id}`,
            data
          );
        }

        var defaultDialerRes;

        if (this.defaultDialerTelecmi == true) {
          defaultDialerRes = self.$http.patch(
            `${self.ENDPOINT}/companies/${self.$store.getters.user.companyId}`,
            {
              defaultDialer: "Telecmi",
            }
          );
        } else {
          defaultDialerRes = self.$http.patch(
            `${self.ENDPOINT}/companies/${self.$store.getters.user.companyId}`,
            {
              defaultDialer: "Phone Dialer",
            }
          );
        }

        this.telecmiUsers = response.body.agents;
        this.connected = true;

        this.$swal({
          title: "Done",
          text: "Connected to Telecmi",
          type: "success",
          confirmButtonText: "OK",
        });
      } else {
        this.$swal({
          title: "Error",
          text: response.body.msg,
          type: "error",
          confirmButtonText: "OK",
        });
      }
    },

    async telecmiList() {
      try {
        var response = await this.$http.post(
          "https://rest.telecmi.com/v2/user/list",
          {
            appid: parseInt(this.appid),
            secret: this.secret,
            page: 1,
            limit: 10,
          }
        );

        console.log("response", response);

        this.telecmiUsers = response.body.agents;
      } catch (e) {
        console.log(e);
      }
    },
    async addGodialTelecmiUsers() {
      const self = this;
      if (!this.godial.user) {
        return this.$swal({
          type: "error",
          text: "Please add a Godial user",
        });
      } else if (!this.telecmi.user) {
        return this.$swal({
          type: "error",
          text: "Please add a TeleCMI user",
        });
      }
      if (this.godial.user && this.telecmi.user) {
        try {
          await self.$http.patch(
            `${self.$store.state.ENDPOINT}/accounts/` + this.godial.user.id,
            {
              telecmi: this.telecmi.user,
            }
          );
          this.godial.user = null;
          this.telecmi.user = null;
          this.fetchAccounts();
        } catch (err) {
          console.error(err);
        }
      }
    },
    async removeGodialTelecmiUsers(item) {
      const self = this;
      console.log(item);

      try {
        await self.$http.patch(`${self.ENDPOINT}/accounts/${item.id}`, {
          telecmi: null,
        });

        this.fetchAccounts();
      } catch (e) {
        console.log(e);
      }
      // this.$store.dispatch("removeGodialTelecmiUser", item);
    },
  },
};
</script>

<style></style>
